.helpContainer {
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .faqSection, .ticketSection, .chatSection, .contactSection, .feedbackSection, .notificationsSection, .troubleshootingSection {
    margin-bottom: 30px;
    background: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Additional styling for buttons, text areas, and layout */
  