/* Header.module.css */
.header {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 5px #0000000a;
    height: 105px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out header content */
    max-width: 1200px; /* Adjust as necessary */
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .location {
    flex: 1; /* Allow it to grow */
    justify-content: center; /* Center location */
  }
  
  .logo {
    display: flex;
    align-items: center;
    flex: 1;
    
  }
  
  .logo img {
    max-height: 80px; /* Example size, adjust as needed */
   
  }
  
  .navigation {
    flex: 2;
    display: flex;
    justify-content: center; 
    align-items: center;
    list-style: none; /* If you use a list for navigation links */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .navigation li {
    padding: 0 15px; /* Spacing between navigation items */
  }
  
  .navigation a {
    text-decoration: none; /* Removes underline from links */
    color: #000000; /* Set your desired color */
    font-family: "Poppins", sans-serif;
    font-size: 1rem; /* Set the font size for navigation links */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .navigation a:hover,
  .navigation a:focus {
    color: #2ca59c; /* Color change on hover/focus */
  }
  
  
  .aboutUsService {
    list-style: none; 
    display: flex;
    /* justify-content: center;  */
    justify-content: space-around;
    gap: 20px; /* Adjust the gap for spacing between navigation items */
    font-size: 0.7rem; /* Reduced font size for navigation items */
    padding: 0; 
    align-items: center;
  }
  
  .userInfo {
    flex: 1; 
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px; /* Space between user info items */
  }
  
  
  
  .boameooo {
    color: transparent;
    font-family: "Poppins", sans-serif;
    /* font-size: 28px;  */
    font-weight: 700;
    /* display: flex; */
    align-items: center; /* Align items vertically */
    gap: 10px; /* Space between Boame and ooo */
  }
  
  .textWrapper {
    font-size: 28px;
    color: #295789;
  }
  .span {
    color: #2ca59c;
    font-size: 28px;
    
  }
  
  .group {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements in the group */
    margin-right: 20px; /* Adds right margin to create space */
  }
  
  
  .unsplash {
    width: 50px;
    height: 50px;
    border-radius: 25px; /* This will make the image round */
    object-fit: cover; /* This ensures the image covers the area without being distorted */
  }
  
  
  .textWrapper2 {
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-size: 15px; /* Adjust font size as needed */
    font-weight: 500;
  }
  
  .polygon {
    width: 13px;
    height: 8px;
  }
  
  .overlapGroupWrapper {
    display: flex;
    align-items: center;
  }
  
  .overlapGroup {
    background-color: #ffffff;
    border: 1px solid #dbdfe4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 10px; /* Padding inside the overlap group */
  }
  
  .img {
    width: 24px;
    height: 16px;
  }
  
  .textWrapper3 {
    color: #262625;
    font-family: "Poppins", sans-serif;
    font-size: 14px; /* Adjust font size as needed */
    font-weight: 500;
    margin-left: 20px; /* Spacing after the image */
  }
  
  .path {
    width: 12px;
    height: 7px;
  }
  
