
 .profileContainer {
    max-width: 800px;
    margin: auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .profileTitle {
    text-align: left; 
    /* font-size: 2.8rem; */
    font-size: 2.2rem;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .profileSection {
    margin-bottom: 30px;
  }
  
  .profileSectionTitle {
    font-size: 2.2rem;
    color: #000000;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formLabel {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .formInput {
    width: 100%;
    padding: 10px;
    font-size: 1.1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .formInputReadOnly {
    background-color: #f3f3f3; /* Light grey background */
    /* Add any other styles for read-only fields */
  }
  
  
  .formSubmitButton {
    width: 100%;
    padding: 12px 0;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s;
  }
  
  .formSubmitButton:hover {
    background-color: #004466;
  }
  
  .orderItem, .paymentMethodItem, .shippingAddress {
    padding: 15px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .profileContainer {
      padding: 20px;
      margin: 20px;
    }
  
    .profileTitle {
      font-size: 2.2rem;
    }
  
    .profileSectionTitle {
      font-size: 1.8rem;
    }
  
    .formInput, .formSubmitButton {
      font-size: 0.9rem;
    }
  }
  