.flutterwavepayments {
  padding: 10px;
  max-width: 800px; /* Match the OrderSummary width */
  /* margin: 20px auto; Center it on the page */
  background-color: #f9f9f9; /* Match the OrderSummary background */
  border-radius: 10px; /* Match the OrderSummary border-radius */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Match the OrderSummary shadow */
  /* display: flex; */
  flex-direction: column;
  align-items: center; /* Center align the items vertically */
}

.container {
  /* width: 90%; Match the OrderSummary container width */
  /* max-width: 400px; Keep it no wider than 400px */
  margin: auto; /* Center it horizontally */
}

.input,
.button {
  width: 100%; /* Make input and button take full width of .container */
  padding: 0.5rem 1rem; /* Adjust padding */
  margin-bottom: 1rem; /* Space between inputs/buttons */
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Border radius */
  font-size: 1rem; /* Font size */
}

/* Additional input styles if needed */
.input {
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

/* Style the button to match the OrderSummary's confirmButton */
.button {
  background-color: #007BFF; /* Button background */
  color: white; /* Button text color */
  cursor: pointer; /* Cursor to indicate it's clickable */
  transition: background-color 0.3s ease; /* Transition for hover effect */
}

.button:hover {
  background-color: #0056b3; /* Button hover state */
}

/* Ensure that the button size and padding matches the OrderSummary */
.button {
  padding: 10px 20px; /* Adjust padding to match */
  font-size: 1em; /* Match font size with OrderSummary */
  margin-top: 20px; /* Provide some top margin */
}

/* You can remove the min-height from .container if it's causing issues with the layout */
