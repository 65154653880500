/* 
.contactContainer {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: 40px auto;
  font-family: 'Arial', sans-serif;
}

.contactContainer h2, .feedbackForm h3 {
  color: #333;
  font-size: 2.2rem;
  margin-bottom: 20px;
  text-align: left;
}

.contactContainer p, .feedbackForm p {
  color: #444;
  line-height: 1.6;
  font-size: 1rem;
  margin-bottom: 20px;
}

.feedbackForm {
  margin-top: 40px;
}

.feedbackInput {
  width: 100%;
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

.submitButton {
  background-color: #005792;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submitButton:hover {
  background-color: #004466;
}


@media (max-width: 768px) {
  .contactContainer {
    padding: 20px;
  }

  .contactContainer h2, .feedbackForm h3 {
    font-size: 1.8rem;
  }
}


.nameInput,
.emailInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.nameInput::placeholder,
.emailInput::placeholder {
  color: #888;
}


.nameInput:hover,
.emailInput:hover,
.feedbackInput:hover {
  border-color: #005792;
}


.feedbackForm p {
  color: #0288d1; 
  font-style: italic;
  text-align: center;
}


@media (max-width: 768px) {
  .nameInput,
  .emailInput {
    padding: 8px;
    font-size: 0.9rem;
  }

  .feedbackInput {
    height: 100px;
  }

  .submitButton {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
} */


/* Contact.module.css */
.contactContainer {
  background-color: #fff;
  padding: 40px;
  margin: 20px auto;
  max-width: 900px; /* Consistent with Home and About */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contactContainer h2, .feedbackForm h3 {
  color: #000000; /* Dark blue for headings */
  font-size: 2.8rem; /* Larger font size for headings */
  margin-bottom: 30px; /* Increased spacing */
  text-align: left;
}

.contactContainer p, .feedbackForm p {
  color: #333; /* Darker text color */
  line-height: 1.8;
  font-size: 1.1rem; /* Slightly larger font size */
  margin-bottom: 20px;
}

.feedbackForm {
  margin-top: 40px;
}

.nameInput,
.emailInput,
.feedbackInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 1rem;
  height: 300px;
}

.nameInput::placeholder,
.emailInput::placeholder {
  color: #888;
}

.submitButton {
  background-color: #000000; /* Bright blue for button */
  /* background-color: #007bff; */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .contactContainer {
    padding: 20px;
    margin: 10px;
  }

  .contactContainer h2, .feedbackForm h3 {
    font-size: 2.2rem;
  }

  .nameInput,
  .emailInput,
  .feedbackInput {
    padding: 8px;
    font-size: 0.9rem;
  }

  .submitButton {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}
