/* EditJobTypeForm.module.css */

.form {
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.inputGroup {
    margin-bottom: 10px;
}

.label, .input, .textarea {
    display: block;
    width: 100%;
}

.input, .textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.textarea {
    resize: vertical;
    min-height: 100px;
}

.actions {
    text-align: right;
}

.saveButton, .cancelButton {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.saveButton {
    background-color: #4CAF50;
    color: white;
}

.cancelButton {
    background-color: #f44336;
    color: white;
}

.error {
    color: #f44336;
    margin-bottom: 10px;
}
