/* EditPartnerProfile.module.css */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title {
    font-size: 24px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.input, .textarea, select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.formGroup {
    margin-bottom: 20px;
}

.radioGroup {
    display: flex;
    gap: 20px;
    align-items: center;
}

.dropdown {
    position: relative;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdownContent label {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #3e8e41;
}

/* Add responsiveness */
@media (max-width: 768px) {
    .container {
        width: 90%;
    }

    .title {
        font-size: 20px;
    }
}
