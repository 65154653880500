/* VoteProposals.module.css */

.voteButton {
  font-size: 10px; /* Adjust the font size as needed */
  padding: 4px 8px; /* Adjust padding for better button sizing */
  margin: 2px; /* Space between buttons */
  border: 1px solid #ccc; /* Border for the buttons */
  background-color: #f8f8f8; /* Background color for the buttons */
  cursor: pointer; /* Change cursor to pointer to indicate clickable area */
  border-radius: 4px; /* Optional: rounded corners for buttons */
  /* Add any additional styling as per your design requirements */
}

.voteWrapper {
  display: flex;
  align-items: center;
}

.voteIcon {
  /* Your styles for the icon */
}

.voteCounts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px; /* Space between icon and text */
}

.supportCount {
  font-size: smaller; /* Adjust as needed */
  position: relative;
  top: -10px; /* Adjust for superscript position */
}

.notSureCount {
  /* Middle text, adjust styles as needed */
}

.doNotSupportCount {
  font-size: smaller; /* Adjust as needed */
  position: relative;
  bottom: -10px; /* Adjust for subscript position */
}


.voteIconAndCounts {
  display: flex;
  align-items: center;
}

.voteCounts {
  display: flex;
  flex-direction: column;
  margin-left: 8px; /* Adjust as needed */
}

.votePercentage {
  font-size: 12px; /* Adjust the font size to fit your design */
  margin: 2px 0; /* Spacing above and below each percentage text */
  color: #333; /* Choose a color that contrasts well with the background */
  font-weight: bold; /* Optionally make the text bold for emphasis */
}

.selectedVote {
  background-color: #4CAF50; /* Or any other color to indicate selection */
  color: white;
}

