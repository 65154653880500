.formContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 600px;
    margin: 0 auto;
    padding: 24px;
    border: 1px solid gray;
    border-radius: 4px;
  }
  
  .formRow {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .inputField,
  .selectField {
    padding: 8px;
    border: 1px solid gray;
    border-radius: 4px;
    width: 100%;
  }
  
  .submitButton {
    padding: 8px 16px;
    color: white;
    /* border: 1px solid gray; */
    background-color: #0056b3;
  }
  .button {
    padding: 8px 16px;
    background-color: #2ca448;
    color: white;
  }