/* 
.myOrdersContainer {
  padding: 20px;
  background-color: #f4f4f4;
}

.ordersTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ordersTable th, .ordersTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.ordersTable th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.ordersTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ordersTable tr:hover {
  background-color: #f1f1f1;
}

 */


 .myOrdersContainer {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px; /* Rounded corners for the container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  max-width: 800px; /* Adjust the maximum width as necessary */
  margin: 0 auto; /* Center the container */
}

.ordersTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 0.95em; /* Slightly smaller font size for a more refined look */
}

.ordersTable th, .ordersTable td {
  border: 1px solid #e0e0e0; /* Lighter border color for subtlety */
  padding: 12px 15px; /* Increased padding for better spacing */
  text-align: left;
}

.ordersTable th {
  background-color: #007bff; /* A nice shade of blue for headers */
  color: white; /* White text for better contrast */
  font-size: 1em; /* Slightly larger font size for headers */
}

.ordersTable tr:nth-child(even) {
  background-color: #f9f9f9; /* Very light gray for every other row */
}

.ordersTable tr:hover {
  background-color: #eff3f6; /* Slightly darker on hover for interactivity */
  cursor: pointer; /* Change cursor to indicate row is interactive */
}

/* Additional Styling for buttons or other elements */
.button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}
