.nav {
  /* background-color: #f9f9f9; */
  background-color: #ffffff;
  display: flex;
  box-shadow: 0px 4px 20px 5px #0000000a;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  height: 105px;
  
}
.logoWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.countryDropdown {
  margin-left: 8px;
 
}


.dropdownsWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.logoContainer {
  position: relative;
  display: flex;
  align-items: center; /* Align items vertically */
}



.logoServiceName {
  font-size: 0.8rem;
  font-weight: bold;
  color: #d3d3d3;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}


.logo {
  font-size: 1.8rem;
  margin: 0 16px 0 0;
}

.servicesDropdown,
.avatarDropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 1rem;
}

/* New styles for buttons inside the dropdown containers */
.servicesDropdown button,
.avatarDropdown button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px; /* Add padding for clickable area */
  /* Additional styling as needed */
}

.nineDots {
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg fill='black'%3E%3Ccircle cx='1.5' cy='1.5' r='1.5'/%3E%3Ccircle cx='9' cy='1.5' r='1.5'/%3E%3Ccircle cx='16.5' cy='1.5' r='1.5'/%3E%3Ccircle cx='1.5' cy='9' r='1.5'/%3E%3Ccircle cx='9' cy='9' r='1.5'/%3E%3Ccircle cx='16.5' cy='9' r='1.5'/%3E%3Ccircle cx='1.5' cy='16.5' r='1.5'/%3E%3Ccircle cx='9' cy='16.5' r='1.5'/%3E%3Ccircle cx='16.5' cy='16.5' r='1.5'/%3E%3C/g%3E%3C/svg%3E");
}

.servicesDropdownContent,
.avatarDropdownContent {
  display: none;
  position: absolute;
  top: calc(100% + 0.25rem);
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  /* z-index: 1; */
  border-radius: 5px;
}

.servicesDropdown:hover .servicesDropdownContent,
.avatarDropdown:hover .avatarDropdownContent {
  display: block;
}

.servicesDropdownContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 16px;
  position: absolute;
  top: calc(100% + 0.25rem);
  background-color: #f9f9f9;
  width: 320px; /* Set a fixed width */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* z-index: 1; */
  border-radius: 5px;
}

.serviceLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 8px;
  width: 80px; /* Set a fixed width */
}


/* Dropdown Menu Styling */
.dropdownMenu {
  background-color: #fff; /* Match with navbar background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Consistent shadow */
  border-radius: 4px; /* Rounded corners */
}


/* .dropdownItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0;
} */
.dropdownItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 0.25rem 0; Reduced padding for less space */
  padding: 8px 12px; /* Slightly larger padding for dropdown items */
  color: #333; /* Consistent text color */
  font-size: 0.9rem; /* Smaller font size */
}

.serviceLink:hover,
.dropdownItem:hover {
  /* background-color: #f1f1f1; */
  background-color: #f2f2f2; /* Slight background change on hover */
}

/* Styling for the NavLink inside dropdown */
.dropdownItem a {
  color: inherit; /* Inherits color from parent */
  text-decoration: none; /* No underline */
}


.serviceIcon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}



.logo {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 60px;
}

.avatarTitle {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Custom class for MenuItems */
.myMenuItem {
  padding: 2px 2px; /* Reduced padding */
  margin: 0; /* Reduced margin */
}


.menuItems {
  display: flex; /* Aligns items in a row */
  margin-left: auto;
  list-style-type: none; /* Ensures no dots are displayed */
  padding: 0; /* Removes default padding */
}

.menuItems li {
  margin-right: 30px;
}

.menuItems a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.inputLabelCountry {
  font-size: 0.75rem;
  line-height: 1;
  transform: translateY(-50%);
}

.activeLink, .menuItems a:hover  {
  color: #18bc9c; /* Medium Bright Green for active/hover state */
  /* Add other styling as needed */
}

/* Add this to ensure consistency across all dropdown items */
.Menu ul {
  padding: 0; /* Removes default padding */
}

.Menu li {
  margin: 0; /* Removes default margin */
}

/* Apply the same styling to other links in the dropdown for consistency */
.Menu a {
  display: block; /* Ensures the full area is clickable */
  padding: 0.25rem 0.5rem; /* Adjust padding as needed */
  font-size: 0.9rem; /* Match font size with .dropdownItem */
  color: inherit; /* Inherits the color from parent */
  text-decoration: none; /* No underline */
}

.Menu a:hover {
  background-color: #f1f1f1; /* Optional: change background on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* Add responsive styles for mobile view */
  .servicesDropdownContent,
  .avatarDropdownContent {
    width: 100%; /* Full width for smaller screens */
    /* Other responsive styles as necessary */
  }
}