.summaryContainer {
  padding: 20px;
  max-width: 800px; /* Adjust as needed for a wider layout */
  margin: 20px auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.section {
  margin-bottom: 20px;
}

.sectionTitle {
  color: #0056b3;
  margin-bottom: 10px;
  font-size: 1.2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.detailsLabel {
  font-weight: bold;
}

.detailsValue {
  color: #333;
}

.confirmButton {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center; /* Center the button */
}

.confirmButton:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (min-width: 768px) {
  .summaryContainer {
    padding: 40px;
  }
}
