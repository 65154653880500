.addBookForm {
  max-width: 600px;
  margin: 0 auto;
  align-items: center;
  padding-top: 5rem;
}
.bookForm {
  display: block;
}

.pageTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.formGroup {
  margin-bottom: 2rem;
}

.formLabel {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

/* .formInput,
.formSelect {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid gray;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
} */

.formInput,
.formSelect {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid gray;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box; /* Add this to fix the input width */
}

.formRadioGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.formRadio {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.chapterInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.chapterNumberInput {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid gray;
  margin-top: 0.5rem;
  margin-right: 1rem;
  width: 100%;
}
.chapterTitleInput {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid gray;
  margin-top: 0.5rem;
  margin-right: 1rem;
  width: 100%;
}
.chapterContentInput {
  width: 100%;
  height: 300px;
  resize: vertical;
  border: 2px solid gray;
  border-radius: 0.5rem;
}


.addChapterBtn,
.removeChapterBtn {
  font-size: 1.5rem;
  background-color: white;
  border: 2px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.removeChapterBtn {
  margin-left: 1rem;
}

.submitBtn {
  font-size: 2rem;
  background-color: green;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.submitBtn:hover {
  background-color: darkgreen;
}


.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

/* .currencySymbol {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
} */
.inputContainer {
  position: relative;
  display: inline-block;
}

/* .currencySymbol {
  margin-left: 5px;
  border-radius: 0.5rem;
  border: 2px solid gray;
  font-size: 1.5rem; 
  font-weight: bold;
  align-self: center; 
} */

/* .currencySymbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #fff;
  padding: 4px;
  border: 2px solid gray;
  border-radius: 0.5rem;
  box-sizing: border-box;
} */
.currencySymbol {
  display: inline-block;
  right: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #fff;
  padding: 4px;
  border: 2px solid gray;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.currencySymbolWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border-radius: 0.5rem;
  border: 2px solid gray;
  width: 100%; 
  height: calc(1.5rem + 4px);
  height: 100%; 
}
