.title {
    text-align: center;
    margin-top: 2rem;
  }

  .h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .p {
    font-size: 1rem;
  }
  
  
  .title-heading {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .title-subheading {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  
 