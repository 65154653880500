.postJobFormContainer {
    max-width: 700px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.jobPostForm {
    display: flex;
    flex-direction: column;
}

.formGroup {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #444444;
}

.input, .select, .textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
}

.select {
    cursor: pointer;
}

.textarea {
    min-height: 120px;
    resize: vertical;
}

.submitButton {
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: #17a2b8; /* Stylish blue color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #138496;
}

.errorMessage {
    color: #dc3545;
    margin-bottom: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .postJobFormContainer {
        padding: 15px;
    }

    .input, .select, .textarea {
        font-size: 14px;
    }
}
