/* AfroForm.module.css */
.formContainer {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.formLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formInput,
.formSelect,
.formTextarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.formButton {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.formButton:hover {
    background-color: #45a049;
}
