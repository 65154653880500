/* DeliveryForm.module.css */
.deliveryFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .deliveryForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px; /* Max width to control form size on larger screens */
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
  }
  
  .addressInfo {
    margin-bottom: 20px;
    background-color: #f1f1f1;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .formControl {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .formControl label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formControl input, 
  .formControl select, 
  .formControl textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .formControl textarea {
    height: 100px; /* Fixed height for textarea */
    resize: vertical; /* Allow vertical resizing */
  }
  
  .submitButton {
    padding: 12px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  /* Responsive styling for larger screens */
  @media (min-width: 768px) {
    .deliveryForm {
      width: 50%;
    }
  }
  
  /* Additional styles for spacing and alignment */
  .formControl input[type="number"], 
  .formControl input[type="text"], 
  .formControl select {
    width: 100%; /* Full width for inputs and select */
  }
  
  /* Spacing between form sections */
  .sectionSeparator {
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
  
  .dimensionsInput {
    display: flex;
    gap: 10px;
  }
  
  .dimensionsInput input {
    flex: 1;
  }

  .formLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.formInput,
.formSelect,
.formTextarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.dimensions {
    display: flex;
    justify-content: space-between;
}

.dimensions > label {
    flex-basis: 30%;
}