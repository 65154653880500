.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.tableText {
    margin-bottom: 30px;
}
  
.cardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
  
.card {
    display: flex;
    align-items: center;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    box-shadow: 4px 4px 6px rgba(0,0,0,0.05);
    padding: 30px;
    margin-bottom: 20px;
    width: 75%; 
    max-width: 1200px; 
}
.cardTitle {
    margin-bottom: 2px;  
}

  
.cardTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 60px; /* Increased margin-left for more space */
}

.cardImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
  
.cardText {
    text-align: left;
}

/* .inputInnerGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 50%; 
}
  
.input {
    width: 90% !important;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
} */

.inputInnerGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%; /* Adjust this to control the width of the input and button container */
}

.input {
    width: 100%; /* Set input width to 100% of its parent container */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

  
.button {
    padding: 10px 20px;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}
