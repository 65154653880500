.container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    margin-bottom: 2rem;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 500px;
  }
  
  .formGroup label {
    margin-bottom: 0.5rem;
  }
  
  .formGroup input,
  .formGroup textarea {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    width: 100%;
  }
  
  .formGroup input:focus,
  .formGroup textarea:focus {
    outline: none;
    border-color: #00bfff;
    box-shadow: 0px 0px 3px #00bfff;
  }
  
  .formGroup button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #00bfff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .formGroup button:hover {
    background-color: #0077b3;
  }
  
  .error {
    color: red;
    margin-bottom: 1rem;
  }
  
  .loading {
    margin-bottom: 1rem;
    font-style: italic;
  }
  