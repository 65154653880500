.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.introductionSection {
    text-align: center;
    margin-bottom: 40px;
}

.introductionSection h2 {
    color: #005a9c; /* Primary color */
    font-size: 2em;
    margin-bottom: 10px;
}

.introductionSection p {
    color: #333;
    font-size: 1em;
    line-height: 1.5;
}

.searchContainer {
    text-align: center;
    margin-bottom: 20px;
}
.searchAndSelect {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.searchInput {
    order: 2; /* This ensures the search input appears second */
    flex-grow: 2; /* Allows the search input to take up more space if available */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
}

.countrySelection {
    margin-bottom: 30px;
    text-align: center;
}

.countryDropdown {
    order: 1; /* This ensures the dropdown appears first */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    color: #333;
    cursor: pointer;
}
.listingsContainer {
    display: flex;
    justify-content: space-between;
}

.exportsSection, .importsSection {
    width: 48%; /* Adjust the width as necessary */
}

.listLayout {
    margin-bottom: 30px;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd; /* for separation */
    padding: 10px;
    background-color: #f9f9f9;
    color: #333;
}

.listItem:hover {
    background-color: #e9e9e9; /* a slight hover effect */
}

.listItem img {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    margin-right: 10px;
    object-fit: cover;
}

.listItem span {
    margin: 0 10px; /* Add spacing around text */
    white-space: nowrap; /* Keep text in a single line */
}

/* Responsive Design */
@media (max-width: 768px) {
    .searchInput,
    .countryDropdown {
        width: 80%;
    }
    .searchAndSelect {
        flex-direction: column;
    }
    .countryDropdown, .searchInput {
        width: 100%;
        margin-bottom: 10px;
    }
    .listingsContainer {
        flex-direction: column;
    }

    .exportsSection, .importsSection {
        width: 100%;
    }

    .listItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .listItem img {
        width: 100%;
        height: auto;
    }

    .listItem span {
        margin: 5px 0; /* Stack with space on small screens */
    }
}
