
.editAddressFormContainer {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .editAddressForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .editAddressForm label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .editAddressForm input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  .googleMapContainer {
    margin-top: 20px;
    margin-bottom: 200px; /* This will add space below the map container */
    position: relative; 
    clear: both; /* Clear floats */
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .saveButton,
  .cancelButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .saveButton {
    background-color: #4CAF50;
    color: white;
  }
  
  .saveButton:hover {
    background-color: #45a049;
  }
  
  .cancelButton {
    background-color: #f44336;
    color: white;
  }
  
  .cancelButton:hover {
    background-color: #da190b;
  }
  
/* 
   .editAddressForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.formGroup {
  margin-bottom: 10px;
}

.input[type="text"],
.select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}



.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.saveButton {
  background-color: #4CAF50;
  color: white;
}

.cancelButton {
  background-color: #f44336;
  color: white;
} */
