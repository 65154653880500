.notificationSettingsContainer {
  max-width: 600px; /* Consistent width with the profile container */
  margin: 20px auto; /* Center the container */
  padding: 20px;
  background-color: #f8f8f8; /* Light background for separation */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.notificationSettingsTitle {
  font-size: 1.5rem; /* Larger font size for the title */
  margin-bottom: 15px; /* Space below the title */
  color: #333333; /* Dark color for contrast */
  text-align: center; /* Center align the title */
}

.notificationSettings {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start */
  gap: 10px; /* Space between each item */
}

.notificationSettings label {
  display: flex;
  align-items: center; /* Align checkbox and text */
  font-size: 1rem; /* Font size for readability */
  cursor: pointer; /* Indicate the label is clickable */
}

.notificationSettings input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and text */
  cursor: pointer; /* Indicate the checkbox is clickable */
}


/* .notificationSettingsContainer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .notificationSettingsTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .notificationSettings {
    display: flex;
    flex-direction: column;
  }
  
  .notificationSettings label {
    margin-bottom: 10px;
  }
  
  .notificationSettings input[type='checkbox'] {
    margin-right: 5px;
  }
   */