/* PostExportOfferForm.module.css */
.form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input,
.select,
.textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.textarea {
    min-height: 100px;
    resize: vertical;
}

.submitButton,
.nextButton,
.prevButton {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton {
    background-color: #4CAF50;
    color: white;
}

.nextButton {
    background-color: #007bff;
    color: white;
}

.prevButton {
    background-color: #6c757d;
    color: white;
}

.submitButton:hover,
.nextButton:hover,
.prevButton:hover {
    opacity: 0.9;
}

.errorMessage {
    color: #ff0000;
    text-align: center;
    margin-top: 10px;
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.prevButton {
    /* Ensures alignment to the left */
    margin-right: auto;
}

.nextButton, .submitButton {
    /* Ensures alignment to the right */
    margin-left: auto;
}
