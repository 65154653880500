.advancedOptionsContainer {
    padding: 15px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input,
.textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
}

.textarea {
    height: 100px;
    resize: vertical;
}

.errorMessage {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #0056b3;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}


.uploadStatus {
    color: #ff0000; /* Adjust color as needed */
    font-size: 14px;
    margin-top: 5px;
}
