
.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: #f7f7f7;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.menuItems {
  display: flex;
  gap: 20px;
}

.userControls {
  display: flex;
  gap: 20px;
}

.navbarContainer a {
  text-decoration: none;
  color: #333;
  padding: 10px 15px;
}

.navbarContainer a:hover {
  background-color: #eee;
}
