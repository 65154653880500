/* BasicDetails.module.css */
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formLabel {
    font-weight: bold;
    color: #333;
}

.input,
.select,
.fileInput,
.reactQuill {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    width: 100%;
}

.input::placeholder,
.fileInput::placeholder {
    color: #666;
}

.input:focus,
.select:focus,
.fileInput:focus {
    border-color: #007bff;
    outline: none;
}

.submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #0056b3;
}

.errorMessage {
    color: #dc3545;
    font-size: 14px;
}

/* ... existing styles ... */

.inlineFormGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inlineInput {
    flex: 1;
    margin-right: 10px;
}

.detailDescription {
    height: 150px; /* Adjust this value as needed */
}


/* Inline form group styles */
.inlineFormGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px; /* Adjust the gap as needed */
}

