   .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
  }
  
  
  .footerLinks {
    display: flex;
    justify-content: center;
  }
  
  .footerLinks a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .activeLink {
    font-weight: bold;
  }
  
  .footerText {
    margin-top: 10px;
    text-align: center;
  }
  