/* serviceshomepage.module.css */

.header {
  background-color: transparent;
  height: 650px;
  position: relative;
  width: 1452px;
}

.overlap38 {
  height: 650px;
  position: relative;
  width: 1450px;
}

.rectangle61 {
  background-color: #ff4f5a;
  height: 650px;
  position: absolute;
  width: 1440px;
}

.maskGroup12 {
  height: 650px;
  position: absolute;
  width: 1450px;
}

.boameooo {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 28px;
  font-weight: 700;
  position: absolute;
  top: 12px;
  left: 43px;
}

.group272 {
  height: 167px;
  position: absolute;
  top: 241px;
  left: 275px;
  width: 892px;
}

.servicesOnDemand {
  color: #ffffff;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 55px;
  font-weight: 700;
  position: absolute;
  text-align: center;
  text-shadow: 0px 0px 20px #0000001a;
  top: -1px;
  left: 78px;
}
.serviceCategories {
  /* background-color: #f6f6f6;  */
  background-color: red; 
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px; /* Adjust the value as per your design */
}

.topCategories {
  height: 160px;
  display: flex;
  gap: 30px;  /* Assuming there's a 30px gap between categories for visual clarity */
  justify-content: start;
  align-items: center;
}

.frame33,
.frame34,
.frame35,
.frame36,
.frame37,
.frame38 {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 10px 20px #00000005;
  height: 160px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vector17,
.frame35,
.vector18,
.union,
.frame39 {
  /* You can adjust the width and height based on your needs or design specifics */
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px; /* space between the icon and the text */
}

.applianceRepair,
.spanWrapper60,
.spanWrapper61,
.categories2,
.spanWrapper62 {
  color: #1c1a1a;
  font-family: "Source Sans Pro", Helvetica;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}


.banners {
  /* Add styles for the promotional banners */
  display: flex;
  justify-content: center;
  gap: 20px;
}

.maskGroup13,
.maskGroup14,
.vector19,
.vector20 {
  /* Add styles for individual banners */
  max-width: 100%;
  height: auto;
}

.serviceSection {
  margin: 20px 0;
}

.serviceTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.serviceGroup {
  display: flex;
  gap: 20px;
}

.serviceItem {
  background-color: #eef2f7; /* example color */
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serviceImage {
  width: 417px;
  height: 267px;
  margin-bottom: 10px;
}

.serviceName {
  text-align: center;
}

.viewAll {
  text-align: right;
  font-size: 16px;
  margin-top: 10px;
  text-decoration: underline;
}


