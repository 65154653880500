.invitationsContainer {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
    background-color: #f9f9f9;
}

.invitationList {
    list-style-type: none;
    padding: 0;
}

.invitationList li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.invitationList li:last-child {
    border-bottom: none;
}

h3 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}
