/* SendInvitation.module.css */

.invitationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Adjust the width as necessary */
    margin: auto;
  }
  
  .inviteeInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .sendButton {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50; /* Use your primary color here */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sendButton:hover {
    background-color: #45a049; /* Darker shade for hover state */
  }
  