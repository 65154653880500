/* AccountDeactivation.module.css
.accountDeactivationContainer {
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.accountDeactivationTitle {
  font-size: 2.2rem;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
}

.accountDeactivationContent {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: center;
}

.accountDeactivationButton {
  display: block;
  width: 100%;
  padding: 12px 0;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.accountDeactivationButton:hover {
  background-color: #c82333;
}


.notificationContainer {
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
  font-size: 1rem;
}

.notificationContainer.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notificationContainer.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.notificationCloseButton {
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
  float: right;
  font-size: 1.2rem;
}
 */


 .accountDeactivationContainer {
  max-width: 800px;
  /* margin: auto; */
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.accountDeactivationTitle {
  text-align: left;
  font-size: 2.2rem;
  color: #000000;
  margin-bottom: 30px;
}

.accountDeactivationContent {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.8;
  margin-bottom: 20px;
}

.accountDeactivationButton {
  
  width: 100%;
  padding: 12px 0;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.accountDeactivationButton:hover {
  background-color: #c82333;
}

/* Add any additional styles needed for uniformity */

/* Responsive adjustments */
@media (max-width: 768px) {
  .accountDeactivationContainer {
    padding: 20px;
    margin: 20px;
  }

  .accountDeactivationTitle {
    font-size: 1.8rem;
  }

  .accountDeactivationButton {
    font-size: 0.9rem;
  }
}
