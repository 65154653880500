.featuredProperties {
}

.imageContainer {
    background-color: #f0f0f0;
    padding: 16px;
    border-radius: 4px;
    display: flex; /* Add this to make it a flex container */
    flex-wrap: wrap; /* Allow wrapping when there's not enough space */
    justify-content: space-between; /* Add space between the images */
  }

  .propertyImage {
    width: 200px; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    object-fit: cover; /* Ensures the aspect ratio is maintained while covering the entire element */
    margin-bottom: 16px; /* Add a margin to the bottom for spacing when wrapped */
  }
  