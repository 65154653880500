.serializedCheckout {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px;
  }
  
  .totalAmount {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .successMessage {
    background-color: #28a745;
    color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    margin-top: 1rem;
  }