/* ConfirmationComponent.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.inputField {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
    margin: 10px 0;
}

.confirmButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

}

.confirmButton:hover {
    background-color: #45a049;
}
.confirmButton:disabled {
    background-color: #ccc;
    cursor: default;
}

.confirmButton:hover:not(:disabled) {
    background-color: #45a049;
}

.message {
    color: #d8000c;
    background-color: #ffbaba;
    margin: 10px 0;
    border: 1px solid;
    padding: 15px 10px;
    border-radius: 3px;
    width: 300px;
    text-align: center;
}
