/* CountrySelector.module.css */
.countrySelector {
    display: flex;
    align-items: center;
  }
  
  .flag {
    width: 30px; /* or your preferred size */
    height: auto;
    margin-right: 8px;
  }
  
  .countryName {
    font-size: 14px;
    /* Add additional styles as needed */
  }
  