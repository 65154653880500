.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 20px;
}

.form input, .form textarea, .form button {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form button {
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.form button:hover {
    background-color: #0056b3;
}

.summary {
    border-top: 1px solid #ccc;
    padding-top: 20px;
}

.summary h2 {
    margin-bottom: 20px;
}

.summary p {
    margin-bottom: 10px;
}

.summary .detail {
    margin-left: 20px;
}

.backButton {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
    text-align: center;
}

.backButton:hover {
    background-color: #0056b3;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.sectionTitle {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.addressForm, .packageForm, .confirmationForm, .orderSummary {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.formControl {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.submitButton {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #0056b3;
}

.addressSelection {
    margin: 20px 0;
  }
  
  .addressSelection h3 {
    margin-bottom: 10px;
    color: #333;
    font-size: 1.2em;
  }
  
  .addressSelection ul {
    list-style-type: none;
    padding: 0;
  }
  
  .addressSelection li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .addressSelection input[type="radio"] {
    margin-right: 10px;
  }
  

  /* Desktop and larger devices */
@media (min-width: 768px) {
    /* Larger padding, different layout configurations, etc. */
  }
  /* Example styles for Sendings component */
.sendingsContainer {
    padding: 20px;
  }
  
  .addressSelection {
    margin-bottom: 20px;
  }
  
  .nextButton {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    /* Responsive button size */
    width: 100%;
  }
  
  /* Larger screens */
  @media (min-width: 768px) {
    .sendingsContainer {
      padding: 40px;
    }
  
    .nextButton {
      width: auto; /* Adjust width for larger screens */
    }
  }
  