
/* AddProposalBlank.module.css */

.container {
  width: 100%;
  /* max-width: 1440px;  */
  margin: auto; /* This will center the container */
  padding: 0 20px; /* This padding will apply to both the title and form */
}

.addProposalBlank .addProposalBlankForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin: 2rem auto;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
}


.div2 {
  width: 100%; /* Stretch to the full available width */
  max-width: 1440px; /* You may set a max-width to match the header/footer */
  margin: auto; /* Center the div if there is space on the sides */
  padding: 0 20px; /* Add some padding on the sides */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}
.topRow {
  display: flex;
  justify-content: space-between; /* This will place space between your items */
  align-items: center; /* This will vertically center your items */
  gap: 16px; /* Adjust the gap between items as needed */
  width: 100%;
}



.addNewProposal {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  position: absolute;
  top: 149px;
  left: 45px; /* Adjust this as needed for your layout */
}

.frame {
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 197px;
  left: 45px;
  width: 1350px; /* This should be responsive, consider max-width instead */
  height: 972px; /* You might want to make this dynamic based on content */
}


.textEditor {
  position: absolute;
  top: 228px;
  left: 25px;
  width: 1302px;
  height: 634px; /* Again, you might want to make this dynamic */
}


.rectangleWrapper {
  position: absolute;
  top: 49px;
  left: 1px;
  width: 1298px;
  height: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0) 100%);
}

.rectangle {
  background-color: #dedede;
  width: 100%; /* Full width of the parent */
  height: 1px; /* Single pixel line */
}


/* Ensure you end with the button styles */
.overlapWrapper {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  top: 887px; /* Adjust as per your layout */
  left: 1135px; /* Adjust as per your layout */
  width: 192px;
  height: 60px;
}

.overlap3 {
  background-color: #2ca59c;
  border-radius: 5px;
  width: 190px;
  height: 60px;
  position: relative;
}

.textWrapper8 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  right: 40px; /* Adjust text alignment as needed */
  top: 18px;
  text-align: right;
}




.label {
  font-size: 0.6rem; 
  display: block; /* Make the label a block element to take full width */
  margin-bottom: 0.5rem; /* Spacing between the label and the textarea */
  text-align: left;
}

.textarea {
  font-size: 0.6rem; 
  padding: 0.5rem;
  margin-bottom: 1rem; /* Spacing after the textarea */
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px; /* Adjustable based on your preference */
  width: 100%; /* Make the textarea take the full width of its parent */
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.fullWidth {
  width: 100%;
  margin-bottom: 1rem; /* Space between form items */
}

.formRow {
  display: flex;
  justify-content:  space-between;  /* space-between; */
  flex-wrap: wrap; /* Wrap on small screens */
  /* gap: 1rem;   */
  width: 100%;
  /* margin: 0 - 8px;  */
  margin-bottom: 1rem;
}


.formRowItem {
  flex: 1;
  min-width: calc(25% - 16px);/* Minimum width of inputs in a row 180px */
  padding: 0 8px;
}


.addProposalBlankForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 800px;  */
  margin: auto;
  padding: 1.5rem;
  box-sizing: border-box;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  width: 100%; /* Take full width to allow justify-content to work */
}


.submitButton {
  margin-top: 1rem;
  padding: 0.7rem 1.5rem;
  background-color: #2ca59c;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #45a049;
}




.select-arrow {
  /* background-image: url('/public/assets/dropdown-arrow-image.jpeg'); */
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 12px;
}


.select,
.input {
  font-size: 0.6rem; 
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Make select and input take the full width of their parent */
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.formTitle {
  font-size: 1.0rem; 
  text-align: left; /* Aligns the text to the left */
  width: 100%; /* Ensures the heading takes the full width */
  margin-top: 1rem; 
}

::placeholder {
  font-size: 0.7rem; /* Adjust the size as needed */
}

.select,
.select option {
  font-size: 0.6rem; /* Adjust the size as needed */
}