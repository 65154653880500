/* Receivals.module.css */
.receivalsContainer {
  padding: 20px;
  background-color: #f4f4f4; /* Background color for the container */
}

/* Styles for the table */
.deliveryOrdersTable {
  width: 100%; /* Full width */
  border-collapse: collapse; /* Remove spaces between borders */
  margin: 20px 0; /* Spacing around the table */
}

/* Styles for table headers */
.deliveryOrdersTable th {
  background-color: #007bff; /* Header background color */
  color: white; /* Header font color */
  font-weight: bold;
  padding: 12px; /* Spacing inside header cells */
  text-align: left;
}

/* Styles for table rows and cells */
.deliveryOrdersTable td {
  padding: 10px; /* Spacing inside cells */
  border-bottom: 1px solid #ddd; /* Border between rows */
}

/* Hover effect for rows */
.deliveryOrdersTable tr:hover {
  background-color: #f5f5f5; /* Color change on row hover */
}

/* Responsive design */
@media (max-width: 600px) {
  .deliveryOrdersTable {
    display: block;
    overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
  }
}

/* Additional card styles */
.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  padding: 15px;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 20px; /* Spacing between cards */
}

.card:hover {
  transform: translateY(-5px);
}

.cardHeader {
  font-size: 1.0em;
  font-weight: 500;
  margin-bottom: 10px;
}

.cardBody {
  font-size: 0.9em;
  line-height: 1.5;
  color: #333;
}

.cardFooter {
  margin-top: auto;
  text-align: right;
}

/* Additional styles for order status segmentation */
.orderStatus {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.orderStatusButton {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.orderStatusButton:hover {
  background-color: #0056b3;
}

.orderStatusButton.active {
  background-color: #28a745;
}
