/* AddChapter.module.css */
.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    color: #555;
  }
  
  .select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .button {
    display: inline-block;
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #218838;
  }
  
  /* If you're using ReactQuill, you might want to add some styling for it as well */
  .ql-container {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  


.addChapterForm {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .pageTitle {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .formInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .chapterForm textarea.formInput {
    resize: vertical;
    min-height: 100px;
  }
  
  .submitBtn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .submitBtn:hover {
    background-color: #0056b3;
  }
  