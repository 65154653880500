.quoteForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .quoteForm label {
    font-size: 1rem;
    font-weight: 500;
    color: #495057;
  }
  
  .quoteForm input,
  .quoteForm select,
  .quoteForm textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .quoteForm input:focus,
  .quoteForm select:focus,
  .quoteForm textarea:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .quoteForm textarea {
    resize: none;
  }
  
  .quoteForm button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .quoteForm button:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
  
  .quoteForm button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  





