.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }

    /* Toggle Switch */
.toggleswitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggleswitch input {
  display: none;
}

.toggleslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #007bff;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

/* Active or Inactive label styling */
.togglelabel {
  margin-right: 1rem;
}


.select-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .select-label {
    margin-right: 1rem;
  }
  
  .select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
  }
  
  .textarea-wrapper {
    margin-bottom: 1rem;
  }
  
  .textarea-label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .textarea {
    width: 100%;
    padding: 0.9rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    overflow: auto;
    height: 200px;
  }
  
  .button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: none;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0069d9;
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }


  

  




