/* WealthForm.module.css */
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.input {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.submitButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: blue;
    color: white;
    cursor: pointer;
}
