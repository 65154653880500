/* ProposalCard.module.css */
  
  .proposalCard {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    /* justify-content: space-between;
    align-items: center; */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    cursor: pointer; /* Indicates that the card is clickable */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .title {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  

  .location {
    font-size: 9px;
    color: #666;
    margin-bottom: 8px;
    /* display: inline-block; */
    /* display: block; */
    white-space: nowrap; /* This prevents the text from wrapping */
    overflow: hidden; /* This will hide any text that overflows */
    text-overflow: ellipsis; /* This will add an ellipsis (...) at the end of the text that overflows */
    width: 100%; /* This ensures the div takes up the full width */
  }
  
  
  .description {
    font-size: 11px;
    color: #444;
    margin-top: 16px;
    margin-bottom: 16px;
    /* border-top: 1px solid #eee;
    border-bottom: 1px solid #eee; */
    /* padding-top: 8px;
    padding-bottom: 8px; */
  }
  
  .details {
    display: flex;
    width: 100%; /* Take the full width to align items properly */
    /* justify-content: space-between;  */
    justify-content: flex-start;
    align-items: center;
    font-size: 10px; /* Adjust font size to your preference */
    color: #666;
    margin-bottom: 8px; /* Adds space between details and interactions if needed */
  }
  
  .author,
  .date {
    margin-right: 8px; /* Space between author and date */
    font-size: 10px; 
  }
  
  .interactions {
    display: flex;
    justify-content: flex-end; /* Align likes/comments to the right */
    /* width: 100%;  */
    align-items: center;
    font-size: 8px; 
    color: #666;
  }
  
  .likes,
  .comments {
    /* display: flex; */
    /* align-items: center; */
    font-size: 10px; 
    margin-left: 0; /* Space between likes and comments */
  }

  .infoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  /* Existing styles */
  .details .author,
  .details .date {
  font-size: 10px;
  /* other styles... */
}

/* Add styles for voting options */
  .voteOptions .support,
  .voteOptions .notSure,
  .voteOptions .doNotSupport {
  font-size: 10px; /* Match the size of .author and .date */
  /* other styles... */
}

  
  /* Icon styles */
  .likes:before,
  .comments:before {
    content: '';
    display: inline-block;
    margin-right: 4px; /* Space between icon and text */
    /* Add your icon background images here */
    /* Example: */
    /* background-image: url('/path/to/like-icon.svg'); */
    /* background-size: contain; */
    /* width: 16px; Height and width should match your icons' dimensions */
    /* height: 16px; */
  }

  .icon {
    height: 16px; /* or the size you prefer */
    width: 16px; /* or the size you prefer */
    margin-right: 4px; /* space between icon and text */
  }
  
  
  /* If you have hover effects or anything else, add them here */
  .proposalCard:hover {
    /* Styles for hover state */
  }