/* Center the main container */
.pictures-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Add a margin to the title */
  .pictures-title {
    margin: 50px 0;
  }
  
  /* Style the upload form */
  .upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .upload-form input[type="file"] {
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #f4f4f4;
    margin-top: 20px;
  }
  
  .upload-form button[type="submit"] {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #2a9d8f;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
  }
  
  /* Style the image grid */
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    margin-bottom: 50px;
  }
  
  .image-item {
    position: relative;
    overflow: hidden;
    background-color: #f4f4f4;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .image-item:hover img {
    transform: scale(1.1);
  }
  
  /* Style the modal */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 999; */
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
  }
  
  .modal-content img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
  }
  
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
  }
  