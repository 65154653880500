.buttonContainer {
    display: flex;
    justify-content: space-between;
  }
  
.updateBookForm {
    max-width: 600px;
    margin: 0 auto;
    align-items: center;
    padding-top: 5rem;
  }
  
  .updateBookForm .bookForm {
    display: block;
  }
  
  .updateBookForm .pageTitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .updateBookForm .formGroup {
    margin-bottom: 2rem;
  }
  
  .updateBookForm .formLabel {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .updateBookForm .formInput,
  .updateBookForm .formSelect {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 2px solid gray;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }
  
  .updateBookForm .chapterInputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .updateBookForm .chapterNumberInput,
  .updateBookForm .chapterTitleInput,
  .updateBookForm .chapterContentInput {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 2px solid gray;
    margin-top: 0.5rem;
    margin-right: 1rem;
    width: 100%;
  }
  
  .updateBookForm .chapterContentInput {
    height: 300px;
    resize: vertical;
  }
  
  .updateBookForm .addChapterBtn,
  .updateBookForm .removeChapterBtn {
    font-size: 1.5rem;
    background-color: white;
    border: 2px solid gray;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .updateBookForm .removeChapterBtn {
    margin-left: 1rem;
  }
  
  .updateBookForm .submitBtn {
    font-size: 2rem;
    background-color: green;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .updateBookForm .submitBtn:hover {
    background-color: darkgreen;
  }
  
  .updateBookForm .error {
    color: red;
    background: pink;
    border: 1px solid red;
    border-radius: 4px;
    padding: 8px;
    margin: 10px 0;
  }
  
  .updateBookForm .inputContainer {
    position: relative;
    display: inline-block;
  }
  
  .updateBookForm .currencySymbol {
    display: inline-block;
    right: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #fff;
    padding: 4px;
    border: 2px solid gray;
    border-radius: 0.5rem;
    box-sizing: border-box;
  }
  
  .updateBookForm .currencySymbolWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border-radius: 0.5rem;
    border: 2px solid gray;
    width: 100%; 
    height: calc(1.5rem + 4px);
  }

  /* CSS for the "Add Chapter" button */
.addButton {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
  }
  
  .addButton:hover {
    background-color: #45a049;
  }
  
  /* CSS for the "Update Book" button */
  .updateButton {
    background-color: #008CBA; /* Blue */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
  }
  
  .updateButton:hover {
    background-color: #007B9F;
  }
  
  /* CSS for the "Remove Chapter" button */
  .removeButton {
    background-color: #f44336; /* Red */
    border: none;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
  }
  
  .removeButton:hover {
    background-color: #da190b;
  }
  