/* OrderStatusManager.module.css */

.orderStatusManagerContainer {
    padding: 20px;
    max-width: 800px;
    margin: 20px auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
}

.ordersList {
    list-style: none;
    padding: 0;
}

.orderItem {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderItem div {
    margin-right: 10px;
}

.actions button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.actions button:hover {
    background-color: #0056b3;
}
