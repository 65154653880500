/* AgentRegistrationForm.module.css */

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .formRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
  
  .inputField,
  .selectField {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.25rem;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
  
  .textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submitButton {
    align-self: center;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  

