.container {
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: space-between; /* This will space your buttons evenly */
  padding-top: 1rem; /* This will add space above your buttons */
  
}

.addButton {
  flex: 1; /* This will make your buttons take up equal space */
  margin: 0 1rem; /* This will add space on the sides of your buttons */
}


.pageTitle {
  text-align: center;
  margin-bottom: 2rem;
}

.bookList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
}

.bookListItem {
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  width: 260px;
}

.bookLink {
  text-decoration: none;
  color: inherit;
}

.bookImage img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.bookDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}



/* Add this style to clear the floats */
.bookListItem::after {
  content: "";
  display: table;
  clear: both;
}

.bookName {
  font-weight: bold;
  font-size: 16px;
}

