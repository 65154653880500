.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.header {
    text-align: center;
    margin-bottom: 40px;
}

.header h2 {
    color: #005a9c;
    font-size: 2em;
    margin-bottom: 10px;
}

.header p {
    color: #333;
    font-size: 1em;
    line-height: 1.5;
}

.searchAndSelect {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.searchInput {
    flex-grow: 2;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
}

.countryDropdown {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    color: #333;
    cursor: pointer;
}

.servicesContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.postedJobs, .requestedWorks {
    width: 48%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin: 10px;
}

.postedJobs h4, .requestedWorks h4 {
    margin-bottom: 20px;
    color: #005a9c;
}

.listLayout {
    display: flex;
    flex-direction: column;
    font-size: 0.85em; /* Reduced font size */
    width: 100%;
}

.listItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.listItem:hover {
    background-color: #e9e9e9;
}

.jobTitle {
    display: block;
    margin-bottom: 5px;
}

.jobDetails {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.listItem img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
}

.listItem span {
    margin-right: 10px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .searchInput, .countryDropdown {
        width: 100%;
        margin-bottom: 10px;
    }

    .searchAndSelect {
        flex-direction: column;
    }

    .servicesContainer {
        flex-direction: column;
    }

    .postedJobs, .requestedWorks {
        width: 100%;
    }

    .listItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .listItem img {
        width: 100%;
        height: auto;
    }

    .listItem span {
        margin-right: 0;
        margin-bottom: 2px;
    }

    .jobDetails {
        flex-direction: column;
        align-items: flex-start;
    }
}
