.landingPage {
  font-family: Arial, sans-serif;
  color: #333;
}

.hero {
  text-align: center;
  min-height: 60vh;
  background-color: rgb(255, 255, 255);
  /* background-image: url('https://via.placeholder.com/1920x1080'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 20px;
  padding: 20px;
}

.hero h2 {
  font-size: 2.5rem;
  color: #000;
  margin-bottom: 1rem;
}

.hero p {
  color: #fff;
  font-size: 1.25rem;
  text-align: center;
  max-width: 80%;
  margin-bottom: 1.5rem;
}

.searchForm {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
  
}

.searchForm input[type="text"] {
  width: 300px;
}

.searchForm select {
  width: 150px;
}



.searchForm input,
.searchForm select {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.searchForm button {
  background-color: #007bff;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.searchForm button:hover {
  background-color: #0056b3;
}

.propertyItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.propertyImage {
  width: 200px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 5px;
}

.propertyDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.propertyDetails h3,
.propertyDetails p {
  margin: 0;
}
