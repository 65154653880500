/* ViewPackageDetails.module.css */
.packageDetailsContainer {
    padding: 20px;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  .title {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .detailItem {
    margin-bottom: 10px;
    font-size: 0.9em;
    line-height: 1.5;
  }
  