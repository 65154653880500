/* AfholderRequest.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px; /* Adjust as needed */
}

.card {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between; /* Space between question text and options */
    align-items: center; /* Vertically align items in the middle */
}

.questionText {
    /* Style for the question text */
    flex: 1; /* Allow the text to take up available space */
    margin-right: 20px; /* Space between text and radio buttons */
}

.radioGroup {
    /* Style for the group of radio buttons */
    display: flex; /* Align radio buttons horizontally */
    gap: 10px; /* Space between radio buttons */
}

.label {
    /* margin: 10px 0; */
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input {
    margin-left: 10px;
}

/* .button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50; 
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
} */

.buttonCard {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    margin-top: 30px; /* Additional space above the button card */
}



.button:disabled {
    background-color: #ccc;
}

.headerText {
    text-align: left;
    max-width: 500px; /* Match the width of your form */
    margin-bottom: 20px; /* Space between the header and the form */
    margin-left: auto;  /* Centering the header */
    margin-right: auto; /* Centering the header */
    padding: 15px; /* Consistent padding with cards */
    font-family: Arial, sans-serif; /* Example font, adjust as needed */
}

.headerText h2 {
    margin-top: 0;
    color: #333; /* Example color, adjust as needed */
    font-size: 1.5em; /* Adjust size as needed */
}

.headerText p {
    color: #666; /* Lighter color for the explanatory text */
    font-size: 1em; /* Adjust size as needed */
    margin-top: 10px; /* Spacing between the header and the paragraph */
}
