.Mouse1 {
    font-family: sans-serif;
    text-align: center;
    padding: 32px;
    border-radius: 16px;
    border: 3px solid #220404;
  }

  .hoverableDiv {
    font-family: sans-serif;
    text-align: center;
  }
  