.wearelookingforForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 1500px;
    margin: 2rem auto;
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .wearelookingforForm label {
    
    font-size: 1rem;
    font-weight: 500;
    color: #495057;
    
  }

  .wearelookingforForm input#title {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .wearelookingforForm input#title:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  
  
  .wearelookingforForm input,
  .wearelookingforForm select,
  .wearelookingforForm textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .wearelookingforForm input:focus,
  .wearelookingforForm select:focus,
  .wearelookingforForm textarea:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .wearelookingforForm textarea {
    resize: none;
  }
  
  .wearelookingforForm button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .wearelookingforForm button:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
  
  .wearelookingforForm button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  
  .wearelookingforForm .quillContainer  {
    width: 100%; /* Full width of the form */
    height: 650px; /* Adjust the height as needed */
    background-color: #fff; /* Background color for the editor */
    border: 1px solid #ced4da; /* Border to match other inputs */
    border-radius: 4px; /* Border radius to match other inputs */
  }
  /* In your CSS file */
.wearelookingforForm .quillContainer .ql-container .ql-editor {
    height: 650px; /* This will change the height of the actual editor area */
  }
  
  

