.notificationsContainer {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.notification {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}
