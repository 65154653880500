/* GeneralDashboardLayout.module.css */

.dashboardContainer {
    display: flex; /* Use flexbox for layout */
    min-height: 100vh; /* Full viewport height */
    background-color: #f5f5f5; /* Light background for the entire dashboard area */
  }
  
  .sidebar {
    width: 250px; /* Fixed width for the sidebar */
    background-color: #ffffff; /* White background for the sidebar */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    /* Add more styling as needed */
  }
  
  .dashboardContent {
    flex-grow: 1; /* Takes up remaining space */
    padding: 20px; /* Padding around the content */
    overflow-y: auto; /* Enables scrolling if content overflows */
    /* Add more styling as needed */
  }
  