.footer {
    height: 455px;
    position: absolute;
    top: 1219px;
    width: 100%;
  }
  
  .rectangle2 {
    background-color: #363231;
    height: 90px;
    position: absolute;
    top: 183px;
    width: 100%;
  }
  
  .p {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 216px;
    padding-left: 45px; /* Adjusted for CSS module */
    padding-right: 45px; /* Adjusted for CSS module */
    white-space: nowrap;
  }
  
  .homePopular {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: right;
    top: 216px;
    right: 45px; /* Adjusted for CSS module */
    white-space: nowrap;
  }
  
  .footerMain {
    background-color: #252323;
    height: 395px;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .group9 {
    position: absolute;
    top: 68px;
    left: 43px;
    width: 407px;
  }
  
  .textWrapper9 {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-weight: 700;
  }
  
  .loremIpsumIs {
    color: #dedede;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    position: absolute;
    top: 50px;
  }
  
  .frame2 {
    position: absolute;
    top: 152px;
  }
  
  .group10 {
    position: absolute;
    top: 68px;
    left: 588px;
  }
  
  .textWrapper10 {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }
  
  .textWrapper11 {
    color: #dedede;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 45px;
    position: absolute;
    top: 45px;
  }
  
  .group11 {
    position: absolute;
    top: 68px;
    left: 810px;
  }
  
  .group12 {
    position: absolute;
    top: 68px;
    left: 1029px;
  }
  
  .addressNmai {
    color: #dedede;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    position: absolute;
    top: 45px;
  }
  
  .textWrapper12 {
    font-weight: 600;
    line-height: 35px;
  }
  
  .textWrapper13 {
    line-height: 24px;
  }
  
  .phone {
    color: #dedede;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    position: absolute;
    top: 134px;
  }
  
  .emailInfoBoameoo {
    color: #dedede;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    position: absolute;
    top: 201px;
  }
  
  .footerSmall {
    height: 60px;
    position: absolute;
    top: 395px;
    width: 100%;
  }
  
  .overlapGroup2 {
    background-color: #2ca59c;
    height: 60px;
    position: relative;
    width: 100%;
  }
  
  .textWrapper14 {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    position: absolute;
    top: 17px;
    white-space: nowrap;
    width: 100%; /* Ensure it spans the full width */
    text-align: center; /* Center the text for responsiveness */
  }
  