.importBasicDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formLabel {
    font-weight: bold;
    color: #333;
}

.input,
.select,
.fileInput,
.reactQuill {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    width: 100%;
}

.input:focus,
.select:focus,
.fileInput:focus {
    border-color: #007bff;
    outline: none;
}

.inlineFormGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.inlineInput {
    flex: 1;
    margin-right: 10px;
}

.detailDescription {
    height: 150px;
}

.errorMessage {
    color: red;
    font-size: 0.8em;
}

.errorInput {
    border: 1px solid red;
}
