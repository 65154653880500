.imgGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin: 20px;
}

.imgWrap {
  overflow: hidden;
  height: 0;
  padding-bottom: 70%;
  position: relative;
}

.imgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}


/* image grid styles
.img-grid{
    margin: 20px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
  .img-wrap{
    overflow: hidden;
    height: 0;
    padding: 50% 0;
    /* padding controls height, will always be perfectly square regardless of width */
    /* position: relative; */
    
    /* Changed opacity: to 1 for now. To be played with later; */
    /* opacity: 1; */
  /* }
  .img-wrap img{
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    position: absolute;
    top: 0;
    left: 0;
  } */ 