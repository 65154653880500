/* ContactDeliveryPerson.module.css */
.contactContainer {
    padding: 20px;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  .title {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
  }
  
  .messageInput {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .sendButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sendButton:hover {
    background-color: #45a049;
  }
  