/* WealthDisplay.module.css */
/* .container {
    padding: 20px 20px 20px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 20px 20px 20px 0; 
    width: 100%;
}

.value {
    margin: 10px 0;
    color: #000000;
    font-weight: bold;
} */

.wealthContainer {
    padding: 20px; /* Consistent padding with Home container */
    border: 1px solid #ccc; /* If needed, adjust to match Home style */
    border-radius: 4px;
    margin: 20px auto; /* Centered margin to align with Home container */
    width: 100%; /* Full width or adjust to match Home container width */
    max-width: 1200px; /* To match Home container max-width */
    text-align: left;
    line-height: 1.5; /* Consistent line height */
    
}

.value {
    margin: 10px 0;
    color: #000000; /* Color matching Home subtitle */
    font-size: 20px; /* Font size matching Home subtitle */
    font-weight: normal;
   
}

/* If you use headings in WealthDisplay */
/*h3 {
    font-size: 45px;  Matching Home title 
    font-weight: bold;
    color: #000000; Matching Home title 
    text-align: center;  Center align if it suits your design 
    margin-bottom: 20px; Consistent spacing 
} */


/*margin: 20px 20px 20px 0;  top, right, bottom, left */
/* color: green; */
/* background-color: #fff; Optional, if you want a background color */