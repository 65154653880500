.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
  background-size: cover;
}

.form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 80vh;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.label span {
  font-weight: bold;
  margin-bottom: 5px;
}

.input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: #0069d9;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.signup {
  margin-top: 30px;
  padding: 10px;
  width: 100%;
}
.spansignup {
  font-weight: bold;
  color: #0069d9;
}






