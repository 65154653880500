.agentCard {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 16px;
  }
  
  .agentLogo {
    width: 134px; /* adjust as needed */
    height: 134px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
  }
  
  .agentDetails {
    margin-left: 20px;
    text-align: right;
  }
  
    
    .agentName {
      margin: 0;
      font-size: 1.2rem;
    }
    
    .agentLocation {
      margin: 0;
      color: #888;
    }
    
    .agentServices {
      margin: 0;
      font-style: italic;
    }
    
    