.importFormContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.description {
    font-size: 16px;
    color: #666;
}

.stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.navigationButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.navButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.navButton:hover {
    background-color: #0056b3;
}

.navButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.errorMessage {
    color: #dc3545;
    font-size: 14px;
    text-align: center;
}
