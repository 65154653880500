.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 900px;
  margin: 0 auto;
}

.page-title {
  margin: 50px 0;
  text-align: center;
  font-size: 36px;
}

.chapter-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 50px 0;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.book-name {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.chapterNumber {
  font-size: 24px;
  font-weight: bold;
  margin-right: 8px;
}

.chapter-title {
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.chapter-content {
  text-align: justify;
  font-size: 18px;
  line-height: 1.6;
  padding: 0 24px;
}

.loading {
  font-size: 18px;
  margin-top: 50px;
}

.error {
  font-size: 18px;
  color: red;
  margin-top: 50px;
}
.navigation {
  display: flex;
  justify-content: space-between; /* This will push the buttons to the left and right extremes */
  width: 100%; /* Use the full width of the container */
  margin-top: 24px; /* Space above the buttons */
  margin-bottom: 24px; /* Space below the buttons */
}

.prevButton, .nextButton {
  padding: 10px 20px; /* Increase clickable area */
  border: none;
  background-color: #333; /* Dark background */
  color: #fff; /* White text */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */

  /* Optional: Add some hover and disabled styles */
  &:hover {
    background-color: #555; /* Slightly lighter on hover */
  }

  &:disabled {
    background-color: #999; /* Grayed out if disabled */
    cursor: not-allowed; /* Indicate the button is disabled */
  }
}




