.importRequirementsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label {
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.quill {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 150px;
}

.datePicker {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
}

.input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
}

.textarea {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
    height: 100px;
}

.uploadedFiles {
    margin-top: 10px;
}

.uploadedFiles div {
    padding: 5px;
    background-color: #e6e6e6;
    border-radius: 4px;
    margin-top: 5px;
}
