.proposalDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .mainContent {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    margin: 20px;
    width: 100%;
    max-width: 80%; /* Adjust as needed based on your design */
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 20px; /* this creates space between flex items */
  }
  
  .commentsTitle {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  .description {
    font-size: 11px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  

  .leftColumn {
    width: 70%; /* Adjust the width as per your design */
    /* Add your existing styles for leftColumn here */
}
.leftColumn h3 {
  /* Adjust your existing styles for h3, change margin-bottom to a smaller value */
  margin-bottom: 2px; /* or whatever value gives you the desired one line space */
}
.details {
  /* Adjust your existing styles for .details, change margin-top to a smaller value */
  margin-top: 1px; /* or remove it entirely if you don't need extra space above .details */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 16px; /* Adjust the space below the details */
}
.detailsAndLocationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* position: relative;  */
  
}

/* Add horizontal lines */
.detailsAndLocationContainer::before,
.detailsAndLocationContainer::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: #ddd;
}

.detailsAndLocationContainer::before {
  top: -8px;
}

.detailsAndLocationContainer::after {
  bottom: -8px;
}

.rightColumn {
    width: 40%; 
    padding: 20px; /* Add padding */
    border: 1px solid #d3d3d3; /* Light grey color for visibility */
}

.commentsHeader {
  /* margin-bottom: 20px; */
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.comment {
    font-size: 11px;
    /* background: #f1f1f1;  */
    background: #f5f5f5;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    
    border-bottom: 1px solid #ccc; /* Add a bottom border to separate comments */

}

.commentText {
  font-size: 11px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; /* Change cursor to indicate it's clickable */
}

.commentsDivider {
  border: none; /* Remove default border */
  height: 1px; /* Set the height of the line */
  background-color: #ccc; /* Set the color of the line */
  margin: 8px 0; /* Add some vertical space before and after the line */
  margin-bottom: 16px; /* Increase bottom margin to add space */
}

.commentAuthor {
  font-weight: bold;
  margin-right: 120px;
}

.commentDate {
  color: #999;
  font-size: 0.8em;
  margin-left: auto; /* Push the date to the right */
}

.addCommentContainer {
  margin-top: 20px;
  width: 92%; 
}

.commentInput {
  width: 100%;
  height: 100px; /* Adjust height as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allows the user to resize the textarea vertically */
  margin-bottom: 10px;
}

.addCommentButton {
  width: 110%;
  padding: 10px 20px;
  background-color: #2CA59C; /* Adjust to match your color scheme */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.attachments {
  display: flex;
  gap: 10px; /* space between attachment items */
}

.attachmentsSection {
  margin-top: 20px;
}

.divider {
  border: 0;
  height: 1px;
  background-color: #d3d3d3; /* same as the rightColumn border color */
  margin: 0; /* to remove any default margin */
  
}

.attachmentsHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

.attachmentsList {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Space between items */
}

.attachmentItem {
  border: 1px solid #d3d3d3;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 130px; /* Fixed square width */
  height: 100px; /* Fixed square height */
}
.attachmentLink {
  display: block;
  text-decoration: none;
  color: #333;
}

.attachmentImage {
  width: 140px; /* Slightly smaller than the container to fit the name */
  height: 90px; /* Slightly smaller than the container to fit the name */
  object-fit: cover; /* Covers the area without stretching the image */
  border-radius: 4px;
}

.attachmentName {
  font-size: 0.8em;
  margin-top: 4px; /* Space between image and text */
  white-space: nowrap; /* Ensures the text stays on one line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis to overflowing text */
  max-width: 80px; /* Ensure text doesn't exceed the image width */
}

.location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 8px; /* Adjust the padding around location items */
  margin: 16px 0; /* Adjust the vertical spacing around location */
}
.locationContainer {
  display: flex;
  flex-grow: 1; /* This will push the voteAndComments to the right */
  align-items: center;
  position: relative;
  padding: 0; /* Remove padding if needed */
  margin-right: 16px; /* Adjust the spacing to the voteAndComments */
  display: flex;
  gap: 10px; /* Adjust gap as needed */
  align-items: center;

}
.location::before,
.location::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: #ddd; /* Your desired <hr> color */
}

.location::before {
  top: 0;
}

.location::after {
  bottom: 0;
}

.location hr {
  display: none; /* Hide the actual <hr> if using pseudo-elements for lines */
}

.locationSegment {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  position: relative;
  padding: 0 4px; /* Adjust the spacing around the text */
  margin-right: 8px;
  font-size: 0.9em; /* Adjust this value as needed */
}

.locationSegment span {
  text-align: left;
}
.locationSegment:not(:last-child)::after {
  content: '|';
  display: block;
  height: 30px; /* Height of the separator line */
  margin: 0 4px; /* Space around the separator */
  background: #ddd; /* Separator color */
  position: absolute;
  right: -12px; /* Adjust the position of the separator line */
  color: #ddd; /* Your desired separator color */
}
.locationValue {
  font-weight: bold;
  margin-top: 4px; /* Space between label and value */
}
.voteAndComments {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px; /* Space between items */
}
.voteAndComments span {
  font-size: 0.9em; /* Adjust this value as needed */
}

.voteIcon {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  /* ...other styles... */
}

.locationLabel {
  font-weight: bold;
  margin-bottom: 5px; /* Space between the label and the value */
}
.voteAndCommentsCount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto; /* This pushes the counts to the right */
}

.voteAndCommentsCount > * {
  margin-bottom: 4px; /* Space between vote and comment counts */
}

.voteAndComments img {
  margin-right: 4px; /* Adjust spacing around icons */
}

