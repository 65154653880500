.earningsContainer {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.earningsList {
    list-style: none;
    padding: 0;
}

.earningsItem {
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.earningsItem div {
    margin-bottom: 10px;
}

.earningsItem p {
    margin: 0;
    color: #333;
    font-size: 16px;
}

.earningsContainer h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}
