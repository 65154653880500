.googleGeocontainer {
    position: relative;
    width: 100%;
    height: 400px; 
    margin-bottom: 80px;
}

.googleGeomapContainer {
    width: 100%;
    height: 100%;
}

.googleGeosearchBox {
    position:absolute;
    top: 10px;
    left: 200px;
    z-index: 5;
    /* width: 200px; */
    /* height: 40px;  */
    /* padding: 5px 10px;  */
    /* padding: 0px; */
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.3);
}


.googleGeolocationDetails {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 200px;
}
