/* Sidebar.module.css */
.sidebar {
  background-color: #fff;
  min-height: 100vh;
  padding: 40px;
  width: 250px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e0e0e0;
}

.sidebar select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  color: #333;
}

.sidebar div {
  font-size: 1rem;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.sidebar div:hover {
  background-color: #f0f0f0;
  color: #005792;
}

@media (max-width: 768px) {
  .sidebar {
    padding: 20px;
    width: 200px;
  }

  .sidebar select,
  .sidebar div {
    font-size: 0.9rem;
  }
}


/* .sidebar {
  background-color: #ffffff;  
  min-height: 100vh;
  padding: 20px;
  width: 250px;  
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);  
  border-right: 1px solid #e0e0e0;  
}

.sidebarTitle {
  font-size: 22px;  
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #d4d4d4; 
  color: #333333; 
}

.sidebarItem {
  font-size: 16px;  
  margin-bottom: 15px;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.sidebarItem:hover {
  background-color: #f0f0f0;  
  color: #007bff;  
}

.sidebarItemActive {
  font-weight: bold;
  background-color: #007bff;  
  color: #ffffff;  
  border-radius: 8px;
}

.servicesDropdown {
  width: 100%;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.serviceTitle {
  margin-top: 25px;  
  margin-bottom: 15px;
  font-size: 20px;  
  color: #333333;  
}

.section {
  margin-top: 25px;  
}

.sectionTitle {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #555555;  
}

.featureList {
  list-style: none;
  padding: 0;
}

.featureListItem {
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.featureListItem:hover {
  text-decoration: underline;
  color: #007bff;  
} */
