.importLogisticsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    font-weight: bold;
    margin-bottom: 5px;
}

.input, .datePicker {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.datePicker {
    cursor: pointer;
}
