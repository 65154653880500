.notification {
    padding: 10px;
    color: white;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 5px;
    background-color: #4caf50; /* default for success */
  }
  
  .notification.error {
    background-color: #f44336; /* for error messages */
  }
  
  /* Add more styles for different types if needed */
  