.deliveryManagementContainer {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.deliveryList {
    list-style: none;
    padding: 0;
}

.deliveryItem {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deliveryItem:last-child {
    border-bottom: none;
}

button {
    background-color: #007bff;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}
