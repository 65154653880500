.deleteBook {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .selectBox {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  
  .deleteBtn {
    font-size: 1.5rem;
    background-color: red;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
  }
  
  .deleteBtn:hover {
    background-color: darkred;
  }
  