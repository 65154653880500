.upform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  width: 80%;
  max-width: 600px;
}

.upform label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2rem;
  background: #f2f2f2;
  color: #555;
  border: 4px dashed #aaa;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.upform label:hover {
  background: #ddd;
}

.upform input {
  display: none;
}

.upform .output {
  margin: 20px 0;
  text-align: center;
}

.upform .error {
  color: red;
  font-weight: bold;
}

.upform .upload-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: #333;
  color: #fff;
}

.upform .upload-btn:hover {
  background: #555;
}

.upform .progress-bar {
  height: 5px;
  background: #ddd;
  margin-top: 20px;
  position: relative;
}

.upform .progress-bar .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #333;
  transition: width 0.3s;
}

.upform .progress-bar .percent {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}





/* .upform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.upform form{
  margin: 30px auto 10px;
  text-align: center;
  
}

.upform label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  color: #333;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.3s ease;
}


.upform label:hover {
  background-color: #333;
  color: white;
}

.upform input[type='file'] {
  display: none;
}

.upform .output {
  margin-top: 20px;
  text-align: center;
}

.upform .error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.upform .output > div {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
} */


/* upload form styles
.upform form{
    margin: 30px auto 10px;
    text-align: center;
  }
  .upform label input{
    height: 0;
    width: 0;
    opacity: 0;
  }
  .upform label{
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid var(--primary);
    border-radius: 50%;
    margin: 10px auto;
    line-height: 30px;
    color: var(--primary);
    font-weight: bold;
    font-size: 24px;
  }
  .upform label:hover{
    background: var(--primary);
    color: white;
  }
  .output{
    height: 60px;
    font-size: 0.8rem;
  }
  .error{
    color: var(--error);
  } */