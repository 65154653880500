
.aboutContainer {
  background-color: #fff; /* Same as Home */
  padding: 20px; /* Adjust to match Home */
  margin: 20px auto; /* Center the container */
  max-width: 1200px; /* Match Home container max-width */
  border-radius: 10px; /* Adjust as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adjust to match Home */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Consistent font-family */
}

.aboutContainer h2 {
  color: #000000; /* Match Home title color */
  font-size: 45px; /* Match Home title size */
  margin-bottom: 20px; /* Consistent spacing */
  text-align: center; /* Center align if it suits your design */
}

.aboutContainer h3 {
  color: #000000; /* Match Home subtitle color */
  font-size: 20px; /* Match Home subtitle size */
  margin-top: 20px;
  margin-bottom: 20px; /* Consistent spacing */
  text-align: left;
}

.aboutContainer p {
  color: #000000; /* Match Home subtitle color */
  font-size: 20px; /* Match Home subtitle size */
  line-height: 1.5; /* Consistent line height */
  margin-bottom: 20px; /* Consistent spacing */
}



/* Add responsive design */
@media (max-width: 768px) {
  .aboutContainer {
    padding: 10px;
    margin: 10px;
  }

  .aboutContainer h2 {
    font-size: 35px;
  }

  .aboutContainer h3 {
    font-size: 18px;
  }
}



