.advertContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.advertArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.imageContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; /* Adjust the space between items by modifying the "20px" value */
}

.propertyImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.advertItem {
  flex-basis: calc(33.333% - 20px);
  margin-bottom: 20px;
}

.advertItem {
  position: relative;
  margin-bottom: 1rem;
}

.advertImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

.mediumRectangle {
  width: 300px;
  height: 250px;
}

.largeRectangle {
  width: 336px;
  height: 280px;
}

.leaderboard {
  width: 728px;
  height: 90px;
}

.wideSkyscraper {
  width: 160px;
  height: 600px;
}

.halfPageAd {
  width: 300px;
  height: 600px;
}

.largeMobileBanner {
  width: 320px;
  height: 100px;
}

.smallSquare {
  width: 200px;
  height: 200px;
}

.square {
  width: 250px;
  height: 250px;
}
