/* RescheduleDelivery.module.css */
.rescheduleContainer {
    padding: 20px;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  .title {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .dateInput,
  .timeInput {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .submitButton {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submitButton:disabled {
    background-color: grey;
  }
  
  .error {
    color: red;
    text-align: center;
  }
  