.addressManagerContainer {
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    padding: 20px;
    background-color: #f5f5f5; /* Adjust the background color as per your theme */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  }
  
  .addressList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .addressItem {
    background-color: white;
    border: 1px solid #ddd; /* Light border for each address item */
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .longAddressFormat, .shortAddressFormat {
    margin-bottom: 10px;
  }
  
  .addressActions {
    display: flex;
    justify-content: flex-start; /* Align buttons to the start */
    gap: 10px;
  }
  
  .toggleButton, .editButton, .deleteButton {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: #007bff; /* Example blue color */
    color: white;
    transition: background-color 0.3s ease;
  
    /* Adjust colors for different button types if needed */
  }
  
  .editButton {
    background-color: #28a745; /* Example green color */
  }
  
  .deleteButton {
    background-color: #dc3545; /* Example red color */
  }
  
  .toggleButton:hover, .editButton:hover, .deleteButton:hover {
    opacity: 0.9;
  }
  