:root {
    --mobile: 480px;
    --tablet: 768px;
    --laptop: 1024px;
    --desktop: 1200px;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #444;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5em;
  }
  
  h2 {
    font-size: 2em;
  }
  
  h3 {
    font-size: 1.375em;
  }
  
  h4 {
    font-size: 1.125em;
  }
  
  h5 {
    font-size: 1em;
  }
  
  h6 {
    font-size: 0.875em;
  }
  
  a {
    color: #0077cc;
    text-decoration: none;
  }
  
  a:hover {
    color: #005fa3;
  }
  
  /* Add any other global styles you want here */
  