.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.error, .loading {
  color: red;
}

.header {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.bookInfo {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.bookInfo h3 {
  margin: 0;
}

.bookInfo p {
  margin: 10px 0;
}

.supportArea {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.supportArea h3 {
  margin: 0;
}

.supportArea p {
  margin: 10px 0;
}

.paymentOptions button {
  margin-right: 10px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.paymentOptions button:hover {
  background-color: #0056b3;
}

.link {
  color: blue;
  text-decoration: underline;
}

/* Add your existing styles here */

.textSection {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.textSection h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333333;
}

.textSection p {
  font-size: 1em;
  line-height: 1.6;
  color: #666666;
}

.readMore {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background-color: #007bff; /* Set default background color */
  color: #fff; /* Set default text color */
}

.readMore:hover {
  background-color: #0056b3;
}

.textSection .readMore {
  background-color: #007bff; /* This will override the default background color when .readMore is inside .textSection */
}

.textSection .readMore:hover {
  background-color: #0056b3; /* This will override the hover background color when .readMore is inside .textSection */
}

.paragraph {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
  text-align: justify;
}
.textSection button.readMore {
  background-color: #007bff;
  color: #fff;
}

.textSection button.readMore:hover {
  background-color: #0056b3;
}
















