/* TradeCategories.module.css */

.form {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inputGroup {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

.submitButton {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: #45a049;
}

.errorMessage {
    color: #ff0000;
    margin-bottom: 10px;
    text-align: center;
}

/* Add any additional styles as needed */
