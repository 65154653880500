/* TradeCategoriesManager.module.css */

.tradeCategoriesManagerContainer {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.categoryList {
    list-style: none;
    padding: 0;
}

.categoryItem {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

.longCategoryFormat, .shortCategoryFormat {
    margin-bottom: 10px;
}

.categoryActions {
    text-align: right;
}

.toggleButton, .editButton, .deleteButton {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggleButton {
    background-color: #6c757d;
    color: white;
}

.editButton {
    background-color: #007bff;
    color: white;
}

.deleteButton {
    background-color: #dc3545;
    color: white;
}

.deleteButton:hover, .editButton:hover, .toggleButton:hover {
    opacity: 0.9;
}

.errorMessage {
    color: #ff0000;
    margin-bottom: 10px;
    text-align: center;
}

.addButton {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px; /* Space between buttons */
}

.addButton:hover {
    background-color: #218838;
}

/* Additional styles can be added as needed */
