

   /* CustomerSupport.module.css */
.customerSupportContainer {
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .customerSupportTitle {
    font-size: 2.2rem;
    color: #000000;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .customerSupportForm {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .formLabel {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .formInput {
  width: 100%; /* Full width of the parent container */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
}


  
  .formTextarea {
    min-height: 150px;
    resize: vertical;
    width: 100%; /* Full width of the parent container */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  }
  
  .formButton {
    display: block;
    width: 100%;
    padding: 12px 0;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s;
  }
  
  .formButton:hover {
    background-color: #000000;
  }
  
  .faqsSection {
    font-size: 1.1rem;
    color: #333;
  }
  
  .faqItem {
    margin-bottom: 15px;
  }
  
  .faqQuestion {
    font-weight: bold;
  }
  
  .faqAnswer {
    margin-top: 10px;
  }
  