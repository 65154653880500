/* ProposalLanding.module.css */


.proposalLanding {
  padding: 20px;
  background-color: #f4f4f4; /* Example background color */
}
.tabsContainer {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px 0;
  align-items: center;
  margin-bottom: 20px;
}

.proposalsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  
}
.addProposalButton {
  background-color: #2CA59C;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  align-self: flex-start;
}

.topSection {
  /* Container for tabs, search, add button, and total proposals count */
}

.tabsAndSearch {
  /* Container for tabs and search bar */
}

.searchAndAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
}

.searchBar {
  flex: 1;
  flex-grow: 1;
  padding-left: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  /* width: 60%;  */
  /* width: 100%;  */
}
.dropdown {
  
  margin-left: 10px; /* Add some space between the search bar and the dropdown */
}

.searchBarContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.filterButton {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
}


.rightColumn {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-left: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.addButton {
  padding: 10px 20px;
  background-color: #2CA59C; /* Example button color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.totalProposals {
  /* margin-left: 20px; */
  /* font-size: 1rem; */
  font-weight: bold;
  font-size: 13px; 
  color: #666;
}

.mainContent {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Two main columns and one for the popular proposals */
  column-gap: 20px;
  
}

.proposalGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-columns: repeat(3, 1fr);  */
  gap: 20px; /* Space between cards */
  /* margin-bottom: 20px; */
}
.tab {
  cursor: pointer;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 16px;
  font-weight: bold;
  color: #333;
  font-size: 10px;
  border-radius: 4px;
  /* Add any hover effects or active styles as needed */
  /* Add other styles for tabs */
}

.activeTab {
  font-weight: bold;
  color: #2ca59c; /* Adjust color for active tab */
  
  /* Add other styles for active tab */
}

/* Below are additional styles you might need based on the classes used in the original code. */

.tabs,
.overlap,
.actionOptions,
.popularProposals {
  /* Add common styles for sections here */
}

.proposalCard {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Ensure you also add styles for elements like buttons, links, images, and other components that were in your original CSS file. */

/* For example, if you had specific styles for a button */
.button {
  background-color: #007bff;
  color: white;
  /* ...other styles */
}



.leftColumn {
  flex: 3;
  /* Your styles here */
}

/* If there are hover effects or other interactive states, include them as well */
.addButton:hover {
  background-color: #449d44; /* Darker shade for hover state */
}

/* Responsive styles if needed */
@media (max-width: 768px) {
  /* .proposalGrid {
    grid-template-columns: 1fr 1fr; 
  } */

  /* .popularProposalsTitle {
    padding: 10px 0;
  } */

  .popularProposalsTitle {
    font-size: 10px; /* Change font size but not working */
    font-weight: bold;
    margin: 20px 0;
  }

  .addButton {
    padding: 10px; /* Adjust padding on smaller screens */
  }
}



