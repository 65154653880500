.advertFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
}

h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.advertForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

advertlabel {
  font-size: 1rem;
  color: #444;
  margin-bottom: 0.5rem;
}

input {
  font-size: 1rem;
  color: #333;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

select {
  font-size: 1rem;
  color: #333;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submitButton {
  font-size: 1rem;
  color: #ffffff;
  background-color: #007bff;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submitButton:hover {
  background-color: #0056b3;
}
