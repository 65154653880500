.rating-container {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  
  .rating__text {
    margin-left: 5px;
    font-size: 19px;
  }
  .rating__star {
    cursor: pointer;
    font-size: 24px;
  }
  
  .rating__star--filled {
    color: gold;
  }
  
  .rating__star--empty {
    color: lightgray;
  }
  