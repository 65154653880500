.footer {
    background-color: transparent;
    height: 276px;
    left: 0;
    position: absolute;
    top: 2342px;
    width: 1440px;
  }
  
  .top {
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
  }
  
  .overlap39 {
    background-color: #454545;
    height: 226px;
    position: relative;
    width: 1440px;
  }
  
  .group288 {
    height: 138px;
    left: 45px;
    position: absolute;
    top: 45px;
    width: 110px;
  }
  
  .group289 {
    height: 138px;
    left: 244px;
    position: absolute;
    top: 45px;
    width: 133px;
  }
  
  .group290 {
    height: 110px;
    left: 444px;
    position: absolute;
    top: 45px;
    width: 76px;
  }
  
  .group291 {
    height: 110px;
    left: 644px;
    position: absolute;
    top: 45px;
    width: 59px;
  }
  
  .group292 {
    height: 97px;
    left: 846px;
    position: absolute;
    top: 45px;
    width: 264px;
  }
  
  .group293 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 53px;
    width: 262px;
  }
  
  .group294 {
    height: 1px;
    left: 1242px;
    position: absolute;
    top: 45px;
    width: 2px;
  }
  
  .group295 {
    height: 1px;
    left: 0;
    position: absolute;
    top: 33px;
    width: 1px;
  }
  
  .overlapGroup41 {
    border-radius: 4px;
    height: 44px;
    width: 120px;
  }
  
  .group296 {
    height: 1px;
    left: 12px;
    position: relative;
    top: 10px;
    width: 1px;
  }
  
  .appStore {
    height: 1px;
    left: 24px;
    position: absolute;
    top: 312px;
    width: 1px;
  }
  
  .downloadOnThe {
    height: 1px;
    left: 26px;
    position: absolute;
    top: 302px;
    width: 1px;
  }
  
  .LOGO {
    height: 22px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
  }
  
  .group297 {
    border-radius: 4px;
    height: 44px;
    left: 0;
    position: absolute;
    top: 87px;
    width: 120px;
  }
  
  .group298 {
    height: 23px;
    left: 10px;
    position: relative;
    top: 11px;
    width: 100px;
  }
  
  .googlePlayLOGO {
    height: 22px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }
  
  .googlePlay {
    height: 15px;
    left: 27px;
    position: absolute;
    top: 9px;
    width: 73px;
  }
  
  .GETITON {
    height: 5px;
    left: 27px;
    position: absolute;
    top: 0;
    width: 34px;
  }
  
  .bottom {
    height: 50px;
    left: 0;
    position: absolute;
    top: 226px;
    width: 1442px;
  }
  
  .elementBoameoooComWrapper {
    background-color: #555555;
    height: 50px;
    position: relative;
    width: 1440px;
  }
  
  .elementBoameoooCom {
    color: #ffffff; /* Adjusted to white */
    font-family: "Source Sans Pro", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 600px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 9px;
    white-space: nowrap;
  }

  /* Footer.module.css */
.footer p {
    color: #ffffff; /* or whatever styles you want */
}
