.container {
  margin: 50px 0;
  padding: 16px; /* add padding */
  margin-left: 16px; /* add margin to the left */
}

.pageTitle {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center; /* add text-align center */
}

.chapterNumber {
  width: 100px;
  text-align: left;
  font-weight: bold;
}

.chapterTitle {
  text-align: right;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
}


.chapterItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.chapterLink {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inactiveChapter {
  color: gray;
  pointer-events: none; /* Make sure it's not clickable */
}


