/* EditTradeCategoryForm.module.css */

.form {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.inputGroup {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.saveButton, .cancelButton {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.saveButton {
    background-color: #4CAF50;
    color: white;
    margin-right: 10px;
}

.cancelButton {
    background-color: #f44336;
    color: white;
}

.saveButton:hover {
    background-color: #45a049;
}

.cancelButton:hover {
    background-color: #d32f2f;
}
