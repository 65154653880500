.messageTitle {
  font-weight: bold;
  color: #000000; /* Keep the title color neutral */
  /* color: #333;  */
  margin-bottom: 0.5rem;
  text-align: left; /* Align title to the left */
  font-size: 22px; /* A bit smaller than the header */
  /* padding: 20px 20px */
}

  .card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    width: 100%;
    /* max-width: 600px; */
    max-width: 100%;
    margin-bottom: 20px;
  }
  /* Add this to ensure images do not overflow the card's boundaries */
.card .messageContent img {
  max-width: 100%;
  height: auto;
}
  

   .card h5 {
    font-size: 26px; /* Slightly larger than the title */
    color: #000000; /* A distinct color for the permanent header */
    /* color: #007bff;  */
    /* margin-bottom: 1rem;  */
    margin-top: 0rem;
    text-align: center; /* Center align the header */
    position: relative; /* Required for positioning the pseudo-element */
  }

  .card h5::after {
    content: '';
    display: block;
    width: 50%; /* Adjust the width of the line as needed */
    height: 2px; /* Adjust the thickness of the line */
    background-color: #000000; /* Line color */
    /* background-color: #007bff;  */
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Center the line horizontally */
    bottom: -10px; /* Adjust distance from the header */
    
  }

  .card p, .card .messageContent {
    font-size: 18px;
    color: #000000;
    /* color: #555; */
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .author {
    font-size: 16px;
    color: #777;
    margin-top: 1rem; /* Add space above the author line */
  }
  
  
  
  @media (max-width: var(--mobile)) {
    .card {
      padding: 15px;
      width: 90%;
    }

    .card h5 {
      font-size: 24px; /* Adjust for mobile */
    }
    .container {
      /* Mobile styles go here */
      padding: 10px;
    }
  
    .title {
      font-size: 35px;
    }
    .messageTitle {
      font-size: 20px; /* Adjust for mobile */
    }
  
    .subtitle {
      font-size: 18px;
    }
  
    .button {
      font-size: 16px;
      padding: 8px 16px;
    }
  
    .card {
      padding: 15px;
      width: 90%;
    }
  }
  
  @media (min-width: var(--mobile)) and (max-width: var(--tablet)) {
    .card {
      padding: 18px;
      width: 85%;
    }
    .container {
      /* Tablet styles go here */
      padding: 15px;
    }
  
    .title {
      font-size: 40px;
    }
  
    .subtitle {
      font-size: 19px;
    }
  
    .button {
      font-size: 17px;
      padding: 9px 18px;
    }
  
    .card {
      padding: 18px;
      width: 85%;
    }
  }
  
  @media (min-width: var(--tablet)) and (max-width: var(--laptop)) {
    .container {
      /* Laptop styles go here */
      padding: 20px;
    }
  }
  
  @media (min-width: var(--desktop)) {
    .card {
      padding: 20px;
      width: 80%;
    }
    .container {
      /* Desktop styles go here */
      padding: 25px;
    }
  
    .title {
      font-size: 45px;
    }
  
    .subtitle {
      font-size: 20px;
    }
  
    .button {
      font-size: 18px;
      padding: 10px 20px;
    }
  
    .card {
      padding: 20px;
      width: 80%;
    }
  }
  