.routeOptimizationContainer {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.optimizeButton {
    background-color: #28a745;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
}

.optimizeButton:hover {
    background-color: #218838;
}

.deliveryList {
    list-style: none;
    padding: 0;
}

.deliveryItem {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
}

.deliveryItem:last-child {
    border-bottom: none;
}
