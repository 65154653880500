.completedCheckout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }
  
  .completedCheckout h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .completedCheckout form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .completedCheckout h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .completedCheckout p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  
  .completedCheckout button {
    font-size: 1rem;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color:#45a049;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .completedCheckout button:hover {
    background-color: #45a049;
  }
  