.deliveryHistoryContainer {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.deliveryList {
    list-style: none;
    padding: 0;
}

.deliveryItem {
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
}

.deliveryItem:last-child {
    border-bottom: none;
}

.statusButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: default;
}
