.form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 80vh;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.signlabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.signlabel span {
  font-weight: bold;
  margin-bottom: 5px;
}

.input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: #0069d9;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
select {
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #f2f2f2;
  width: 100%;
}

.login {
  margin-top: 30px;
  padding: 10px;
  width: 100%;
}
.spanlogin {
  font-weight: bold;
  color: #0069d9;
}

.phoneNumberContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
  
}

.phoneCode {
  /* background-color: #f2f2f2; */
  background-color: #f2f2f2;
  padding: 9px;
  border: 1px solid #ccc;
  /* border-radius: 5px 0 0 5px; */
  border-radius: 5px;
  margin-right: -1px;  /*Aligns the border with the input field */
  font-size: 0.6rem;
  color: #333;
  
  
}

.input.phoneNumber {
  border-radius: 0 5px 5px 0;
  flex-grow: 1; /* Allows the input to fill the rest of the space */
  
}










