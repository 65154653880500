.container {
    padding: 20px;
    background-color: #fff5f5;
}

.title {
    text-align: center;
    color: #28a745;
    margin-bottom: 30px;
}

.jobCard {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.jobTitle {
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
}

.jobDescription {
    color: #666;
    font-size: 16px;
    margin-bottom: 10px;
}

.jobDetails {
    color: #333;
    font-size: 14px;
}
