/* BusinessProfile.module.css */
.profileContainer {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.profileSection {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.profileSection:last-child {
    margin-bottom: 0;
}

/* Add additional styles as needed */
