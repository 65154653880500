.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.container h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
}

.container p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 10px;
}

.container p strong {
    color: #333;
    margin-right: 5px;
}

.imagesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.productImage {
    max-width: 100%;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
}
