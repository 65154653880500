.invitationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.inviteInput {
    margin: 10px 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.inviteButton {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.inviteButton:hover {
    background-color: #45a049;
}
