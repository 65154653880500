.searchResultsPage {
    display: flex;
    gap: 24px;
    padding: 24px;
  }
  
  .sidebar {
    flex: 1;
    border: 1px solid #e2e2e2;
    padding: 16px;
    border-radius: 4px;
  }
  
  .propertyList {
    flex: 2;
  }
  
  .hit {
    padding: 16px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  