
.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Changed from space-evenly for better spacing */
  height: auto; /* Adjusted from 100vh for flexible height */
  background-size: cover;
  max-width: 1200px; /* Setting a maximum width */
  margin: 0 auto; /* Center the container */
  padding: 20px;
}
.wealthDisplayContainer {
  width: 100%; /* Full width of the container */
  
  /* margin-top: 20px; Spacing above the WealthDisplay */
}
.homeContainer h2 {
  color: #000000; /* Match Home title color */
  font-size: 45px; /* Match Home title size */
  margin-bottom: 20px; /* Consistent spacing */
  text-align: center; /* Center align if it suits your design */
}

.homeContainer h3 {
  color: #000000; /* Match Home subtitle color */
  font-size: 20px; /* Match Home subtitle size */
  margin-top: 20px;
  margin-bottom: 20px; /* Consistent spacing */
  text-align: left;
}

.homeContainer p {
  color: #000000; /* Match Home subtitle color */
  font-size: 20px; /* Match Home subtitle size */
  line-height: 1.5; /* Consistent line height */
  margin-bottom: 20px; /* Consistent spacing */
}

.title {
  font-size: 45px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 20px;
  color: #000000;
  text-align: left;
  margin-bottom: 40px;
}

.button {
  font-size: 18px;
  color: #fff;
  background-color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #555;
}

.card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
}

.card h5 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.card p {
  font-size: 18px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 10px;
}

.author {
  font-size: 16px;
  color: #777;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.postQuoteButton,
.ratingButton {
  font-size: 14px;
  color: #fff;
  background-color: #333;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.postQuoteButton:hover,
.ratingButton:hover {
  background-color: #555;
}

@media (max-width: var(--mobile)) {
  .container {
    /* Mobile styles go here */
    padding: 10px;
  }

  .title {
    font-size: 35px;
  }

  .subtitle {
    font-size: 18px;
  }

  .button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .card {
    padding: 15px;
    width: 90%;
  }
}

@media (min-width: var(--mobile)) and (max-width: var(--tablet)) {
  .container {
    /* Tablet styles go here */
    padding: 15px;
  }

  .title {
    font-size: 40px;
  }

  .subtitle {
    font-size: 19px;
  }

  .button {
    font-size: 17px;
    padding: 9px 18px;
  }

  .card {
    padding: 18px;
    width: 85%;
  }
}

@media (min-width: var(--tablet)) and (max-width: var(--laptop)) {
  .container {
    /* Laptop styles go here */
    padding: 20px;
  }
}

@media (min-width: var(--desktop)) {
  .container {
    /* Desktop styles go here */
    padding: 25px;
  }

  .title {
    font-size: 45px;
  }

  .subtitle {
    font-size: 20px;
  }

  .button {
    font-size: 18px;
    padding: 10px 20px;
  }

  .card {
    padding: 20px;
    width: 80%;
  }
}
