.feedbackContainer {
    background-color: #f4f4f4;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .feedbackContainer h2 {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .feedbackContainer p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .mission,
  .vision,
  .values,
  .contact {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .mission h3,
  .vision h3,
  .values h3,
  .contact h3 {
    font-size: 2rem;
    color: #444;
    margin-bottom: 15px;
  }
  
  .values ul {
    padding-left: 20px;
  }
  
  .values li {
    list-style-type: disc;
    color: #555;
    margin-bottom: 10px;
  }
  
  .contact p {
    margin-bottom: 10px;
  }
  