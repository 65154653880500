.packagePickupContainer {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .packageList {
    list-style: none;
    padding: 0;
  }
  
  .packageItem {
    padding: 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .packageItem:last-child {
    border-bottom: none;
  }
  
  .packageItem div {
    margin-right: 20px;
  }
  
  .packageItem button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .packageItem button:hover {
    background-color: #0056b3;
  }
  